<template>
  <v-app-bar id="app-bar">
    <v-spacer></v-spacer>
    <div id="navi-button-bar">
      <v-slide-group show-arrows>
        <v-menu
          v-for="section in filterVisibleSections()"
          :key="section.id"
          bottom
          :offset-y="true"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              class="menubutton"
              v-bind="props"
              @click="checkContent(section.id)"
            >
              {{ section.name }}
            </v-btn>
          </template>
          <v-list class="list" v-if="getFilteredList(section.id).length > 1">
            <v-list-item
              class="list-item"
              v-for="(item, index) in getFilteredList(section.id)"
              :key="index"
              @click="emit('navigate', 'Content', item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu v-if="isAdmin" bottom :offset-y="true">
          <template v-slot:activator="{ props }">
            <v-btn class="menubutton" v-bind="props">
              Käyttäjien hallinta
            </v-btn>
          </template>
          <v-list class="list">
            <v-list-item
              class="list-item"
              @click="$emit('navigate', 'UserManagement')"
            >
              Käyttäjäryhmien hallinta
            </v-list-item>
            <v-list-item class="list-item" @click="$emit('navigate', 'Email')">
              Sähköpostin lähetys
            </v-list-item>
            <v-list-item
              class="list-item"
              @click="$emit('navigate', 'ImportPersons')"
            >
              Tuo käyttäjiä
            </v-list-item>
          </v-list>
        </v-menu>
      </v-slide-group>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script setup>
import { store } from "@/store.js";

const props = defineProps({
  sections: Array,
  isAdmin: Boolean,
  userName: String,
});

const emit = defineEmits(["navigate"]);

// opens content page directly if there's only one content page under section
const checkContent = (sectionId) => {
  const filteredList = getFilteredList(sectionId);
  if (filteredList.length == 1) {
    emit("navigate", "Content", filteredList[0]);
  }
};

const getFilteredList = (sectionId) => {
  return store.content.filter((item) => item.sectionId === sectionId);
};

const filterVisibleSections = () => {
  return props.sections.filter((section) => getFilteredList(section.id).length);
};
</script>

<style scoped>
#app-bar {
  background-color: var(--main-color);
  color: white;
}

#navi-button-bar {
  min-width: 640px;
  text-align: center;
}
#app .menubutton {
  background: var(--main-color);
  color: white;
  font-size: 1rem;
  text-transform: unset;
  height: 44px;
  font-family: var(--main-font);
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0.45rem;
}

#app .menubutton:hover {
  background: var(--hover-color);
}

.list {
  background: var(--main-color) !important;
}

.list-item {
  background: var(--main-color);
  color: white;
}

.list-item:hover {
  background: var(--hover-color);
}
</style>
