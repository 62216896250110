<template>
  <v-container class="content">
    <success-snackbar ref="snackbar" />
    <confirmation-dialog ref="dialog" />
    <h1 class="page-title">Käyttäjäryhmien hallinta</h1>
    <v-row>
      <v-col>
        <v-select
          :items="groups"
          v-model="visibleGroups"
          multiple
          item-text="name"
          label="Näytettävät ryhmät"
          variant="outlined"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="visibleGroups.length !== groups.length"
              ><v-chip>{{ item.name }}</v-chip>
            </span>
            <span v-else-if="index === groups.length - 1">Kaikki</span>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="Suodata hakusanalla"
          v-model="searchInput"
          variant="outlined"
          @input="filterResults()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-list id="user-list" :key="listKey" expand>
      <v-list-group v-for="user in filteredResults" :key="user.id" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item prepend-icon="mdi-account-circle" v-bind="props">
            <v-list-item-title>
              {{ createName(user) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item class="group-item" v-for="group in groups" :key="group.id">
          <template v-slot:prepend>
            <v-list-item-action start>
              <v-checkbox-btn
                :model-value="belongsToGroup(user, group)"
                @change="updateGroups(user, group)"
                color="info"
                class="group-checkbox"
              />
              <span class="checkbox-label">{{ group.name }}</span>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-group>
    </v-list>
    <div class="buttonbar">
      <v-btn class="button" @click="reset()">Palauta alkuperäiset</v-btn>
      <v-btn class="button" @click="save()">Tallenna</v-btn>
    </div>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import _ from "lodash";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import SuccessSnackbar from "../components/SuccessSnackbar.vue";

const props = defineProps({ users: Array, groups: Array });
const emit = defineEmits(["update"]);

const listKey = ref(1);
const updatedData = ref([]);
const searchInput = ref("");
const visibleGroups = ref([]);
const filteredResults = ref([]);
const snackbar = ref(null);
const dialog = ref(null);

onMounted(() => {
  visibleGroups.value = props.groups.map((group) => group.name);
  filterResults();
  reset();
});

const belongsToGroup = (user, group) => {
  return user.groups.some((userGroup) => userGroup.id === group.id);
};

const reset = () => {
  searchInput.value = "";
  visibleGroups.value = props.groups.map((group) => group.name);
  listKey.value++;
  updatedData.value = _.cloneDeep(props.users);
};

const save = () => {
  const usersToSave = createModifiedUserList();
  emit("update", {
    users: usersToSave,
    callbackFn: createSnackbar,
  });
};

const createSnackbar = (success) => {
  snackbar.value?.show(success);
};

const updateGroups = (user, group) => {
  updatedData.value = updatedData.value.map((data) => {
    if (data.id === user.id) {
      const isInGroup = belongsToGroup(user, group);
      if (!isInGroup) {
        data.groups = data.groups.concat(
          props.groups.find((contentGroup) => contentGroup.id === group.id)
        );
      } else {
        data.groups = data.groups.filter(
          (contentGroup) => contentGroup.id !== group.id
        );
      }
    }
    return data;
  });
};

const filterResults = () => {
  if (visibleGroups.value.length == props.groups.length) {
    filteredResults.value = props.users;
  }
  filteredResults.value = props.users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchInput.value.toLowerCase()) &&
      user.groups.some((group) =>
        visibleGroups.value.some((visibleGroup) => visibleGroup === group.name)
      )
  );
};
const createModifiedUserList = () => {
  const modifiedUsers = [];
  props.users.forEach((user) => {
    const updatedUser = updatedData.value.find((data) => data.id === user.id);
    if (
      !_.isEqual(
        _.sortBy(user.groups, ["id", "name"]),
        _.sortBy(updatedUser.groups, ["id", "name"])
      )
    ) {
      modifiedUsers.push(updatedUser);
    }
  });
  return modifiedUsers;
};
const createName = (user) => {
  return user.name.length ? user.name : user.userName;
};

onBeforeRouteLeave(async (_to, _from, next) => {
  if (createModifiedUserList().length !== 0) {
    const answer = dialog.value?.open();
    if (!answer) {
      next(false);
      return;
    }
  }
  next();
});
</script>

<style scoped>
#user-list {
  border: 1px solid var(--main-color);
  padding: 0;
  width: 100%;
}

#user-list .v-list-group:nth-child(2n) {
  background: var(--main-color-light);
}

#user-list .v-list-group:nth-child(2n + 1) {
  background: var(--main-color-lighter);
}

#user-list .group-item {
  background: white;
}

.checkbox-label {
  padding-left: 1rem;
}

#filter {
  width: 100%;
  display: flex;
  padding-top: 20px;
}

#filter .v-select {
  max-width: 40%;
  padding-right: 20px;
}

#filter .v-text-field {
  width: 50%;
}
</style>
