<template>
  <div class="content">
    <success-snackbar
      ref="snackbar"
      successText="Sähköposti lähetetty"
      errorText="Lähetys epäonnistui"
    />
    <h1 class="page-title">Sähköpostin lähetys</h1>
    <div id="editor-container">
      <ConfirmationDialog ref="dialog" />
      <v-select
        :items="senders"
        :menu-props="{ bottom: true, offsetY: true }"
        v-model="sender"
        label="Lähettäjä"
        variant="outlined"
      >
      </v-select>
      <v-select
        :items="groups"
        :menu-props="{ bottom: true, offsetY: true }"
        v-model="selectedGroups"
        multiple
        item-text="name"
        label="Vastaanottaja"
        variant="outlined"
        return-object
      >
      </v-select>
      <v-text-field
        label="Aihe"
        v-model="subject"
        variant="outlined"
      ></v-text-field>
      <ckeditor id="editor" v-model="message" :editor="editor"></ckeditor>
    </div>
    <div class="buttonbar">
      <v-btn class="button" @click="clear()">Tyhjennä</v-btn>
      <v-btn class="button" @click="send()" :disabled="isSendDisabled()"
        >Lähetä</v-btn
      >
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import SuccessSnackbar from "../components/SuccessSnackbar.vue";

export default {
  name: "EmailView",
  components: {
    ckeditor: CKEditor.component,
    ConfirmationDialog,
    SuccessSnackbar,
  },
  props: {
    groups: Array,
  },
  data() {
    return {
      editor: ClassicEditor,
      selectedGroups: [],
      subject: "",
      message: "",
      snackbar: false,
      snackbarColor: "",
      snackbarText: "",
      snackbarIcon: "",
      senders: [],
      sender: "",
    };
  },
  created() {
    this.senders = [
      "pauli.kiuru@terveydentuottajat.fi",
      "teo.lagerwall@terveydentuottajat.fi",
    ];
  },
  methods: {
    send() {
      this.$emit("email", {
        subject: this.subject,
        message: this.message,
        groups: this.selectedGroups,
        sender: this.sender,
        callbackFn: this.createSnackbar,
      });
    },
    clear() {
      this.selectedGroups = [];
      this.subject = "";
      this.message = "";
      this.sender = "";
    },
    isSendDisabled() {
      return (
        this.subject === "" ||
        this.message === "" ||
        this.selectedGroups.length == 0 ||
        this.sender === ""
      );
    },
    createSnackbar(success) {
      if (success) this.clear();
      this.$refs.snackbar.show(success);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.subject !== "" ||
      this.message !== "" ||
      this.selectedGroups.length !== 0
    ) {
      const response = await this.$refs.dialog.open();
      if (!response) {
        next(false);
        return;
      }
    }
    next();
  },
};
</script>

<style>
.ck-editor__editable {
  width: 100%;
  height: 300px;
}

#editor-container {
  flex: 1 100%;
}

#editor-container p {
  margin: 0;
}

#editor-container .ck-content {
  padding: 8px;
}
</style>
