<template>
  <v-container>
    <v-app-bar id="login-app-bar" dense />
    <div class="inner-block">
      <img id="big-logo" :src="require('@/assets/tt_logo.jpg')" />
      <div class="error-message" v-if="error">
        <p>
          Sinulla ei ole oikeutta tähän sovellukseen. Voit pyytää käyttöoikeutta
          Terveyden Tuottajien tukipalveluiden kautta.
        </p>
      </div>
      <div v-else>
        <h3>Kirjaudu sisään Microsoft-tunnuksilla</h3>
        <v-btn id="login-button" @click="$emit('login')">Kirjaudu</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const error = ref(false);

onMounted(() => {
  if (route.query?.error) {
    error.value = true;
  }
});
</script>

<style scoped>
.inner-block {
  max-width: 600px;
  min-width: 350px;
  margin: 8rem auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  text-align: center;
}

#login-app-bar {
  background-color: var(--main-color);
}

#big-logo {
  width: 100%;
}

#login-button {
  background: var(--main-color);
  color: white;
  font-size: 16px;
  width: 140px;
  height: 44px;
  margin: 36px 0 8px 0;
  font-family: var(--main-font);
  letter-spacing: normal;
}

.error-message {
  border: 1px solid #ef9a9a;
  background-color: #ffcdd2;
  border-radius: 2px;
  padding: 24px 12px 12px 12px;
}
</style>
