import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/HomeView";
import ContentView from "./views/ContentView";
import UserManagement from "./views/UserManagement";
import LoginView from "./views/LoginView";
import EmailView from "./views/EmailView";
import ContentPreview from "./views/ContentPreview";
import UserInfo from "./views/UserInfo";
import ImportPersons from "./views/ImportPersons";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/:section/:title",
    name: "Content",
    component: ContentView,
    beforeEnter: (to, _from, next) => {
      if (to.params.section && to.params.title) {
        next();
      } else {
        next({ name: "Home" }); // redirect to home if content is missing
      }
    },
  },
  {
    path: "/kayttajien-hallinta",
    name: "UserManagement",
    component: UserManagement,
  },
  {
    path: "/sahkopostin-lahetys",
    name: "Email",
    component: EmailView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    props: true,
  },
  {
    path: "/virhe",
    name: "Error",
    component: LoginView,
    beforeEnter: (_to, _from, next) => {
      next({ name: "Login", query: { error: true } }); // redirect to login page with errors if login unsuccessful
    },
  },
  {
    path: "/backend/login",
    name: "BackendLogin",
    beforeEnter() {
      location.href = process.env.VUE_APP_LOGIN_URL;
    },
  },
  {
    path: "/preview/:id",
    name: "ContentPreview",
    component: ContentPreview,
  },
  {
    path: "/muokkaa-tietoja",
    name: "UserInfo",
    component: UserInfo,
  },
  {
    path: "/tuo-kayttajia",
    name: "ImportPersons",
    component: ImportPersons,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    !from.name &&
    to.name !== "Home" &&
    to.path !== "/virhe" &&
    to.name !== "Login"
  ) {
    next({ name: "Home" }); // redirect to home on page reload
  } else {
    next();
  }
});

export default router;
