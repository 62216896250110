<template>
  <v-snackbar
    location="top"
    v-model="showSnackbar"
    id="snackbar"
    :color="snackbarColor"
  >
    <v-icon>{{ snackbarIcon }}</v-icon>
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
export default {
  name: "SuccessSnackbar",
  props: {
    successText: String,
    errorText: String,
  },
  data() {
    return {
      showSnackbar: false,
      snackbarColor: "",
      snackbarIcon: "",
      snackbarText: "",
      defaultSuccessText: "Käyttäjätiedot päivitetty",
      defaultErrorText: "Tietojen päivitys epäonnistui",
    };
  },
  methods: {
    show(success) {
      if (success) {
        this.snackbarColor = "success";
        this.snackbarIcon = "mdi-check-circle";
        this.snackbarText = this.successText
          ? this.successText
          : this.defaultSuccessText;
      } else {
        this.snackbarColor = "error";
        this.snackbarIcon = "mdi-alert-circle";
        this.snackbarText = this.errorText
          ? this.errorText
          : this.defaultErrorText;
      }
      this.showSnackbar = true;
    },
  },
};
</script>

<style scoped></style>
