<template>
  <div class="content">
    <success-snackbar ref="snackbar" />
    <h1 class="page-title">Tuo käyttäjiä</h1>
    <p>
      Tuo käyttäjiä ja päivitä tietoja. Lisää excel-tiedosto alla olevaan
      tiedostokenttään. Tiedoston tulee olla .xlsx- tai .xls-muodossa ja
      sisältää neljä saraketta seuraavassa järjestyksessä: 1. Sukunimi, 2.
      Etunimi, 3. Sähköposti, 4. Käyttäjätunnus.
    </p>
    <div class="upload-div">
      <v-file-input
        accept=".xlsx, .xls"
        variant="outlined"
        class="file-input"
        v-model="file"
      ></v-file-input>
      <v-btn class="button" @click="save()" :disabled="file == undefined">
        Lataa
        <v-icon right>mdi-cloud-upload</v-icon>
      </v-btn>
    </div>
    <p class="error-text">{{ errorText }}</p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { readFile, utils } from "xlsx";
import SuccessSnackbar from "../components/SuccessSnackbar.vue";

const emit = defineEmits(["import"]);

const file = ref(null);
const errorText = ref("");
const snackbar = ref(null);

const save = async () => {
  if (file.value?.name.endsWith(".xlsx") || file.value?.name.endsWith(".xls")) {
    errorText.value = "";
    const data = await file.value?.arrayBuffer();
    const workbook = readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    emit("import", {
      users: createJsonData(worksheet),
      callbackFn: createSnackbar,
    });
    file.value = null;
  } else {
    errorText.value = "Virhe: Tiedoston tulee olla .xlsx- tai .xls-muodossa";
  }
};

const createJsonData = (worksheet) => {
  const jsonList = utils.sheet_to_json(worksheet);
  if (!jsonList.length || Object.keys(jsonList[0]).length !== 4) {
    errorText.value = "Virhe: Tiedoston tulee sisältää neljä saraketta.";
    return;
  }
  return jsonList.map((json) => {
    return {
      name: `${json[Object.keys(json)[0]]} ${json[Object.keys(json)[1]]}`,
      userName: json[Object.keys(json)[3]],
      email: json[Object.keys(json)[2]],
    };
  });
};

const createSnackbar = (success) => {
  snackbar.value?.show(success);
};
</script>

<style scoped>
.upload-div {
  display: flex;
  padding: 24px 0;
  height: 80px;
  align-items: center;
}

.file-input {
  max-width: 70%;
  margin-top: 32px;
}

.error-text {
  color: crimson;
  font-size: 18px;
  padding-left: 36px;
}
</style>
