<template>
  <v-dialog class="dialog" v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title> Vahvista sivulta poistuminen </v-card-title>
      <v-card-text>
        Sinulla on tallentamattomia muutoksia. Haluatko varmasti poistua
        sivulta?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()"> Ei </v-btn>
        <v-btn text @click="confirm()"> Kyllä </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";

const dialog = ref(false);
const resolve = ref(() => null);

const open = () => {
  dialog.value = true;
  return new Promise((res) => {
    resolve.value = res;
  });
};

const confirm = () => {
  resolve.value(true);
  dialog.value = false;
};

const cancel = () => {
  resolve.value(false);
  dialog.value = false;
};

defineExpose({ open });
</script>
