<template>
  <div>
    <MobileAppBar
      v-if="mobile"
      :sections="sections"
      :content="content"
      :isAdmin="isAdmin"
      @navigate="navigateToPage"
      @navigate-home="navigateHome"
      @logout="$emit('logout')"
    />
    <v-col v-show="!mobile">
      <v-app-bar elevation="0">
        <v-btn
          class="icon-button icon"
          icon="mdi-home"
          elevation="0"
          @click="navigateHome()"
        >
        </v-btn>
        <img
          class="logo"
          alt="Terveyden Tuottajat intranet etusivu"
          :src="require('@/assets/logo_pieni.png')"
          @click="navigateHome()"
        />
        <v-spacer />
        <v-menu :offset-y="true">
          <template v-slot:activator="{ props }">
            <v-btn
              class="icon-button"
              elevation="0"
              append-icon="mdi-cog"
              v-bind="props"
            >
              {{ userName }}
              <template v-slot:append>
                <v-icon class="icon"></v-icon>
              </template>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="navigateToPage('UserInfo')">
              Muokkaa käyttäjätietoja
            </v-list-item>
            <v-list-item
              title="Kirjaudu ulos"
              prepend-icon="mdi-logout"
              @click="$emit('logout')"
            >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <NavigationBar
        :sections="sections"
        :content="content"
        :isAdmin="isAdmin"
        @navigate="navigateToPage"
      />
    </v-col>
  </div>
</template>

<script setup>
import NavigationBar from "./NavigationBar";
import MobileAppBar from "./MobileAppBar";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const router = useRouter();
const { mobile } = useDisplay();
defineProps({
  sections: Array,
  content: Array,
  isAdmin: Boolean,
  userName: String,
});

const navigateToPage = (name, item) => {
  router
    .push({
      name: name,
      params: item ? { title: item.title, section: item.section } : undefined,
    })
    .catch((error) => console.error(error));
};

const navigateHome = () => {
  router.push("/").catch((error) => console.error(error));
};
</script>

<style scoped>
.img-div {
  background-color: #fcfcfc;
  height: 66px;
  display: flex;
  align-items: center;
}

.logo {
  height: 36px;
  margin: 18px 12px 12px 4px;
  cursor: pointer;
}

#app .icon-button {
  margin: 12px;
  margin-left: 16px;
  font-family: var(--main-font);
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: unset;
  background-color: #fcfcfc;
}

#app .icon {
  font-size: 1.5rem;
  color: #747474;
}

#cog-icon {
  padding-left: 12px;
}
</style>
