<template>
  <div>
    <v-app-bar>
      <v-app-bar-nav-icon
        id="nav-icon"
        @click.stop="showDrawer = !showDrawer"
      ></v-app-bar-nav-icon>
      <img
        class="mobile-logo"
        alt="Terveyden Tuottajat intranet etusivu"
        :src="require('@/assets/logo_pieni.png')"
        @click="$emit('navigate-home')"
      />
    </v-app-bar>
    <v-navigation-drawer temporary absolute v-model="showDrawer" id="list">
      <v-list>
        <v-list-item
          class="list-item"
          @click="$emit('navigate-home')"
          prepend-icon="mdi-home"
        >
          <v-list-item-title> Etusivu </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="(section, index) in filterVisibleSections()" :key="index">
          <v-list-item
            class="list-item"
            v-if="getFilteredList(section.id).length == 1"
            @click="
              $emit('navigate', 'Content', getFilteredList(section.id)[0])
            "
          >
            <v-list-item-title>{{ section.name }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else no-action>
            <template v-slot:activator="{ props }">
              <v-list-item class="list-item" v-bind="props">
                <v-list-item-title>{{ section.name }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              class="list-item"
              v-for="(item, index) in getFilteredList(section.id)"
              :key="index"
              @click="$emit('navigate', 'Content', item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="isAdmin">
          <v-list-item
            class="list-item"
            @click="$emit('navigate', 'UserManagement')"
          >
            <v-list-item-title>Käyttäjien hallinta</v-list-item-title>
          </v-list-item>
          <v-list-item class="list-item" @click="$emit('navigate', 'Email')">
            <v-list-item-title>Sähköpostin lähetys</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="list-item"
            @click="$emit('navigate', 'ImportPersons')"
          >
            <v-list-item-title>Tuo henkilöitä</v-list-item-title>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <v-list-item class="list-item" @click="$emit('navigate', 'UserInfo')">
          <v-list-item-title> Muokkaa käyttäjätietoja </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="list-item"
          @click="$emit('logout')"
          prepend-icon="mdi-logout"
        >
          <v-list-item-title> Kirjaudu ulos </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: "MobileAppBar",
  props: {
    sections: Array,
    isAdmin: Boolean,
  },
  data: () => ({
    showDrawer: false,
  }),
  methods: {
    getFilteredList(sectionId) {
      return store.content.filter((item) => item.sectionId === sectionId);
    },
    filterVisibleSections() {
      return this.sections.filter(
        (section) => this.getFilteredList(section.id).length
      );
    },
  },
};
</script>

<style scoped>
#icon-div {
  width: 100%;
  height: 32px;
  padding: 0 6px;
}

#list {
  background: var(--main-color);
}

#list .list-item {
  color: white;
  cursor: pointer;
  width: 100%;
}

#list .v-icon {
  color: white;
}

#nav-icon {
  color: var(--main-color);
}

.mobile-logo {
  width: 200px;
}

#close-button {
  margin: 5px;
}
</style>
