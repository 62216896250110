<template>
  <div class="content">
    <success-snackbar ref="snackbar" />
    <confirmation-dialog ref="dialog" />
    <h1 class="page-title">Käyttäjätietojen muokkaus</h1>
    <div class="info-row">
      <div class="left-column">Nimi:</div>
      <div class="right-column">{{ user.name }}</div>
    </div>
    <div class="info-row">
      <div class="left-column">Käyttäjätunnus:</div>
      <div class="right-column">{{ user.userName }}</div>
    </div>
    <v-divider class="divider"></v-divider>
    <div class="info-row">
      <div class="left-column">Sähköposti:</div>
      <div class="right-column">
        <v-text-field
          class="info-field"
          variant="outlined"
          label="Sähköposti"
          v-model="email"
        ></v-text-field>
      </div>
    </div>
    <div class="buttonbar">
      <v-btn class="button" @click="reset()">Palauta alkuperäiset</v-btn>
      <v-btn class="button" @click="save()">Tallenna</v-btn>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import SuccessSnackbar from "../components/SuccessSnackbar.vue";

const props = defineProps({ user: Object });
const emit = defineEmits(["update-info"]);
const email = ref("");
const snackbar = ref(null);
const dialog = ref(null);

onMounted(() => {
  email.value = props.user?.email;
});

const reset = () => {
  email.value = props.user.email;
};

const save = () => {
  const modifiedUser = {
    id: props.user.id,
    name: props.user.name,
    email: email.value,
    userName: props.user.userName,
  };
  emit("update-info", {
    user: modifiedUser,
    callbackFn: createSnackbar,
  });
};

const createSnackbar = (success) => {
  snackbar.value?.show(success);
};

onBeforeRouteLeave(async (_to, _from, next) => {
  if (email.value !== props.user.email) {
    const response = await dialog.value?.open();
    if (!response) {
      next(false);
      return;
    }
  }
  next();
});
</script>

<style scoped>
.left-column {
  width: 20%;
  min-width: 130px;
}

.right-column {
  min-width: 60%;
  display: flex;
  align-items: center;
}

.info-row {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 16px 0;
}

.info-field {
  margin-top: 28px;
}

.divider {
  margin: 24px 0 32px 0;
}
</style>
