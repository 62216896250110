<template>
  <v-app>
    <AppBar
      v-if="loggedIn"
      :sections="sections"
      :isAdmin="isAdmin"
      :userName="loggedInUser.name"
      @logout="logout"
    />
    <router-view
      v-if="loggedIn || previewItem"
      :users="users"
      :groups="groups"
      :homePage="homePage"
      :previewItem="previewItem"
      :user="loggedInUser"
      @update="updateUserGroups"
      @email="sendEmail"
      @update-info="updateUserInfo"
      @import="importUsers"
    />
    <router-view v-if="!loggedIn" @login="login" />
  </v-app>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import AppBar from "./components/AppBar.vue";
import { useRouter } from "vue-router";
import { store } from "./store.js";

const router = useRouter();

const homePage = ref({});
const sections = ref([]);
const isAdmin = ref(false);
const users = ref([]);
const groups = ref([]);
const loggedIn = ref(false);
const userToken = ref("");
const loggedInUser = ref({});
const previewItem = ref(null);

onBeforeMount(async () => {
  if (window.location.pathname.startsWith("/preview/")) {
    getPreview(window.location.pathname);
    return;
  }
  initToken();
  if (!(await authenticate())) {
    router.push({ name: "Login" }).catch((error) => console.error(error));
  } else {
    getData();
  }
});
const getData = async () => {
  fetch(`/backend?token=${userToken.value}`)
    .then((response) => response.json())
    .then((data) => {
      try {
        loggedInUser.value = data.user;
        users.value = data.users;
        isAdmin.value = data.isAdmin;
        sections.value = data.sections.map((item) => {
          return {
            id: item.sys.id,
            name: item.fields.osionNimi,
          };
        });
        store.content = data.content.flatMap((item) => {
          if (item.fields.otsikko === "Etusivu") {
            homePage.value = createContentItem(item);
            return [];
          }
          const contentItem = createContentItem(item);
          if (new Date(contentItem.publishDate) > new Date()) {
            return [];
          }
          return contentItem;
        });
        groups.value = data.groups.map((item) => {
          return {
            id: item.sys.id,
            name: item.fields.ryhmanNimi,
          };
        });
      } catch (error) {
        console.error(data.errorMessage);
        loggedIn.value = data.status === 401;
      }
    });
};

const updateUserGroups = (data) => {
  fetch(`/backend/users?token=${userToken.value}`, {
    method: "POST",
    headers: { "Content-Type": "application/json " },
    body: JSON.stringify(data.users),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.users) {
        users.value = data.users;
        return true;
      } else if (data.errorMessage) {
        console.error(data.errorMessage);
        return false;
      }
    })
    .then((success) => data.callbackFn(success));
};

const initToken = () => {
  const hash = window.location.hash;
  if (hash.length > 0) {
    const params = new URLSearchParams(hash.substring(1));
    userToken.value = params.get("token");
    router.push({ name: "Home" });
  }
};

const login = () => {
  router.push({ name: "BackendLogin" });
};

const logout = () => {
  fetch("/backend/logout").then(() => {
    loggedIn.value = false;
    userToken.value = "";
    router.push({ name: "Login" });
  });
};

const authenticate = async () => {
  return await fetch(`/backend/auth`)
    .then((response) => {
      if (response.status === 302) {
        loggedIn.value = true;
        return response.json();
      }
    })
    .then((data) => {
      if (data?.token) {
        userToken.value = data.token;
      }
      return loggedIn.value;
    });
};

const createContentItem = (item) => {
  const rendererOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, next) =>
        `<h2 class="heading">${next(node.content)}</h3>`,
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) =>
        `<img src="${fields.file.url}" class="embedded-image" alt="${fields.description}"/>`,
    },
  };
  const files = item.fields.liitetiedostot?.map((file) => {
    return {
      name: file.fields?.title,
      url: "https:" + file.fields?.file?.url,
    };
  });
  return {
    id: item.sys.id,
    title: item.fields.otsikko,
    publishDate: item.fields.julkaisuajankohta,
    text: documentToHtmlString(item.fields.sisalto, rendererOptions),
    sidebarText: documentToHtmlString(item.fields.sivupalkki),
    sectionId: item.fields.osio?.sys.id,
    section: item.fields.osio?.fields.osionNimi,
    files: files,
  };
};

const sendEmail = (email) => {
  fetch(`/backend/email?token=${userToken.value}`, {
    method: "POST",
    headers: { "Content-Type": "application/json " },
    body: JSON.stringify({
      subject: email.subject,
      message: email.message,
      groups: email.groups,
      sender: email.sender,
    }),
  }).then((response) => {
    const success = response.status === 200;
    email.callbackFn(success);
    if (!success) {
      response.json().then((data) => console.error(data.errorMessage));
    }
  });
};

const getPreview = (url) => {
  fetch(`/backend${url}`)
    .then((response) => response.json())
    .then((data) => {
      if (!data.errorMessage) {
        previewItem.value = createContentItem(data);
      } else {
        console.error(data.errorMessage);
      }
    });
};

const updateUserInfo = (info) => {
  fetch(`/backend/user-info?token=${userToken.value}`, {
    method: "POST",
    headers: { "Content-Type": "application/json " },
    body: JSON.stringify({
      id: info.user.id,
      name: info.user.name,
      userName: info.user.userName,
      email: info.user.email,
    }),
  }).then((response) => {
    const success = response.status === 200;
    info.callbackFn(success);
    response.json().then((data) => {
      if (!success) {
        console.error(data.errorMessage);
      } else {
        loggedInUser.value.email = data.email;
      }
    });
  });
};

const importUsers = (info) => {
  fetch(`/backend/import-users?token=${userToken.value}`, {
    method: "POST",
    headers: { "Content-Type": "application/json " },
    body: JSON.stringify(info.users),
  }).then((response) => {
    const success = response.status === 200;
    info.callbackFn(success);
    response.json().then((data) => {
      if (!success) {
        console.error(data.errorMessage);
      } else {
        users.value = data.users;
      }
    });
  });
};
</script>

<style>
:root {
  --main-color: rgb(0, 157, 165);
  --hover-color: rgb(2, 178, 187);
  --main-color-light: rgb(218, 240, 243);
  --main-color-lighter: rgb(236, 247, 249);
  --main-font: "Lato", sans-serif;
}

#app {
  font-family: var(--main-font);
  font-size: 1rem;
  line-height: 1.5;
}

a {
  color: #1976d2;
}

@media screen and (max-width: 960px) {
  .content {
    width: 90%;
    align-self: center;
    background-color: white;
    margin: 60px 0 24px 0;
    padding: 3rem 2rem;
  }
}

@media screen and (min-width: 960px) {
  .content {
    width: 80rem;
    height: 100%;
    margin: 4rem 0 1rem 0;
    align-self: center;
    background-color: white;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 5rem 4rem;
  }
}

.page-title {
  padding-bottom: 2rem;
  font-size: 2rem;
}

.heading {
  padding-bottom: 12px;
}

.embedded-image {
  max-width: 100%;
  padding: 32px 0;
}

#app .button {
  background: var(--main-color);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-transform: unset;
  width: 160px;
  height: 44px;
  margin: 12px;
  font-family: var(--main-font);
  font-weight: normal;
  letter-spacing: normal;
}

#app .button:hover {
  background: var(--hover-color);
  font-weight: 800;
  width: 164px;
  margin: 10px;
  height: 48px;
}

.buttonbar {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

p {
  margin-bottom: 16px;
}
</style>
