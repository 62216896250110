<template>
  <v-container fill-height class="content">
    <v-row>
      <h1 class="page-title">{{ createTitle() }}</h1>
    </v-row>
    <v-row>
      <v-col>
        <v-row><span v-html="contentItem.text"></span></v-row>
        <span v-if="contentItem.files">
          <h3 id="link-list-title">Tiedostot</h3>
          <v-row v-for="file in contentItem.files" :key="file.url">
            <a
              class="file-link"
              :href="file.url"
              target="_blank"
              title="Avaa tiedosto"
            >
              {{ file.name }}
            </a>
          </v-row>
        </span>
      </v-col>
      <v-col
        class="right-column"
        v-show="contentItem.sidebarText"
        sm="12"
        md="5"
        ><span v-html="contentItem.sidebarText"></span
      ></v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onMounted, onUpdated, ref } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store.js";

const props = defineProps({
  homePage: Object,
});

const contentItem = ref({});

const route = useRoute();

onUpdated(() => {
  if (route.name === "Home") {
    contentItem.value = props.homePage;
  } else if (route.params?.title && route.params?.section) {
    contentItem.value = store.content.find(
      (c) =>
        c.title === route.params.title && c.section === route.params.section
    );
  }
});

onMounted(() => {
  if (route.name === "Home") {
    contentItem.value = props.homePage;
  } else if (route.params?.title && route.params?.section) {
    contentItem.value = store.content.find(
      (c) =>
        c.title === route.params.title && c.section === route.params.section
    );
  }
});

const createTitle = () => {
  if (route.name === "Home") {
    return "";
  } else if (store.contentItem) {
    return !store.contentItem.section ||
      store.contentItem.section === store.contentItem.title
      ? store.contentItem.title
      : `${store.contentItem.section} – ${store.contentItem.title}`;
  } else {
    return !route.params.section ||
      route.params?.section === route.params?.title
      ? route.params?.title
      : `${route.params?.section} – ${route.params?.title}`;
  }
};
</script>

<style scoped>
#link-list-title {
  padding: 16px 0;
}

.file-link {
  color: var(--main-color);
}

@media screen and (min-width: 960px) {
  .right-column {
    padding-left: 4rem;
  }
}

#counter-container {
  flex: 1 100%;
  min-width: 500px;
  padding-top: 16px;
}
</style>
